@use '../custom' as *;
@use '../components/mixins' as *;

.get-started {
  padding: 4rem 0;
  background-color: $gray-200;
}

.cta-info {
  padding: 3rem;
  margin: 1rem 0;

  &__list {
    position: relative;
    list-style-type: none;
    display: block;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    margin-bottom: 0.7rem;

    li::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f00c';
      font-weight: 600;
      margin-right: 0.5rem;
      font-size: 1.2rem;
    }
  }
}

// FORM CONTENT
.form {
  padding: 3rem;

  button {
    border: none;
    color: $white;
    text-transform: capitalize;

    a {
      color: $white;
      text-decoration: none;
      text-transform: capitalize;
    }
  }
}
