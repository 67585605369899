@use '../custom' as *;
@use '../components/mixins' as *;

.portfolio {
  padding: 4rem 0;

  button {
    text-transform: capitalize;
  }
}

.portfolio-box {
  position: relative;
  width: 100%;
  height: 18.5rem;
  overflow: hidden;
  margin-bottom: 1.2rem;
  border-top: 0.5rem solid lighten($primary, 20%);

  img {
    width: 100%;
    height: 100%;
  }

  .portfolio-info {
    background-color: rgba($primary, 0.75);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;

    .caption {
      text-align: center;
      color: $white;
      @include absoluteCenter;

      h4 {
        text-transform: uppercase;
        display: block;
      }
    }
  }
  &:hover {
    img {
      transform: scale(1.5);
      @include transitionProperty;
    }
    .portfolio-info {
      opacity: 1;
      visibility: visible;
      @include transitionProperty;
    }
  }
}
