@use '@angular/material' as mat;

@use './assets/scss/custom/components/animations';
@use './assets/scss/custom/components/buttons';
@use './assets/scss/custom/components/mixins';
@use './assets/scss/custom/components/typography';
@use './assets/scss/custom/fontawesome';

//import sections
@use './assets/scss/custom/sections/navbar';
@use './assets/scss/custom/sections/intro-section';
@use './assets/scss/custom/sections/companies';
@use './assets/scss/custom/sections/services';
@use './assets/scss/custom/sections/testimonials';
@use './assets/scss/custom/sections/faq';
@use './assets/scss/custom/sections/portfolio';
@use './assets/scss/custom/sections/get-started';
@use './assets/scss/custom/sections/footer';

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
    typography: mat.define-typography-config(),
  )
);

@include mat.core-theme($my-theme);
@include mat.button-theme($my-theme);
@include mat.progress-spinner-theme($my-theme);
@include mat.slide-toggle-theme($my-theme);

@import './assets/scss/custom/custom';
.logo {
  height: 100px;
}
