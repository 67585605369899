.companies {
  padding: 0 0 5rem 0;

  &__logo-box {
    max-width: 100%;
    height: 3rem;
    margin-bottom: 2rem;
    padding: 0.5rem;
    text-align: center;
    position: relative;

    img {
      height: 100%;

      &:hover {
        filter: grayscale(120);
      }
    }
  }
}
